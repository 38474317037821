import React, { FC } from 'react';
import { parseBoolean } from 'utils/parseBoolean';
import classnames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';
import Button from 'common/Button';
import BlockCarousel from 'components/BlockCarousel';
import PrevArrow from 'components/PDCarousel/components/prevArrow';
import NextArrow from 'components/PDCarousel/components/nextArrow';
import Step from 'components/Step/Step';
import { UmbracoStepList } from '@shared/types/umbraco/compositions/stepList';

import { SLIDER_SETTINGS } from './constants';

import './StepList.scss';

const StepList: FC<UmbracoStepList.IStructure> = ({
  properties: { steps, showNumber, isHorizontal = '0', waiSettings, isSliderOnMobile },
}) => {
  const stepsCSSClassNames = classnames('step-list', {
    'step-list--with-numbers': parseBoolean(showNumber),
    'step-list--horizontal': parseBoolean(isHorizontal),
  });
  const { isDesktop } = useScreenRecognition();

  const settings = {
    customPaging: (i) => <Button ariaLabel={`Go to slide ${i + 1}`} variant="icon" />,
    prevArrow: <PrevArrow ariaLabel={waiSettings?.ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={waiSettings?.ariaNext} />,
  };

  const curSettings = { ...SLIDER_SETTINGS, ...settings };

  return steps?.length ? (
    <div className={stepsCSSClassNames} data-testid="step-list">
      {isSliderOnMobile && !isDesktop ? (
        <BlockCarousel settings={curSettings}>
          {steps.map((step) => (
            <Step
              key={step.properties.description}
              {...step}
              isHorizontal={parseBoolean(isHorizontal)}
            />
          ))}
        </BlockCarousel>
      ) : (
        steps.map((step) => (
          <Step
            key={step.properties.description}
            {...step}
            isHorizontal={parseBoolean(isHorizontal)}
          />
        ))
      )}
    </div>
  ) : null;
};

export default StepList;
